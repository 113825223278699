import React, {useState} from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { FaCirclePlus } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';




const Dashboard = () => {
    const Navigate = useNavigate()
    return (
        <div className='flex'>
            <aside className="basis-1/5 sticky top-0">
                <Sidebar />
            </aside>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <div>
                    <Navbar />
                </div>
                <div className='flex flex-col gap-5 justify-center items-center'>
                    <h1 className='text-3xl font-bold mt-40'>Welcome to Mindnotes Admin Panel</h1>
                    <h1 className='text-3xl font-bold'>{new Date().toDateString()}</h1>
                </div>
                {/* <div className='flex justify-end mt-32'>
                    <div onClick={()=>Navigate('/createLetter')} className='mr-32'>
                        <FaCirclePlus size={40} className='hover:text-blue-600 cursor-pointer' />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Dashboard