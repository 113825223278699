import { API_ENDPOINT } from "../../config";

export async function getConversationList(queryParams, headers) {
    try {
        let url = `${API_ENDPOINT}/conversations/admin/getAll?`;
        url += queryParams.join('&');
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getSingleConversation(id, headers) {
    try {
        const url = `${API_ENDPOINT}/conversations/admin/${id}`
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function replyToConversation(id, requestOptions) {
    try {
        const url = `${API_ENDPOINT}/conversations/${id}`
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        return data
    } catch (error) {

    }
}

export async function deleteConversation(id, requestOptions) {
    try {
        const url = `${API_ENDPOINT}/conversations/${id}`
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error);
    }
}

export async function getLettersList(queryParams, headers) {
    try {
        let url = `${API_ENDPOINT}/letters/admin/getAll?`;
        url += queryParams.join('&');
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getSingleLetter(id, headers) {
    try {
        const url = `${API_ENDPOINT}/letters/admin/${id}`
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}


export async function createLetter(requestOptions) {
    try {
        const url = `${API_ENDPOINT}/letters`
        const response = await fetch(url, requestOptions)
        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}


export async function updateLetter(sequenceNumber, requestOptions) {
    try {
        const url = `${API_ENDPOINT}/letters/${sequenceNumber}`
        const response = await fetch(url, requestOptions)
        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}