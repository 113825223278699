import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom';
import Therapist from './pages/therapist/allTherapist/AllTherapist';
import LettersList from './pages/letters/allLetters/LettersList';
import TherapistProfile from './pages/therapist/therapistProfile/TherapistProfile'
import CreateTherapist from './pages/therapist/createTherapist/CreateTherapist';
import CreateLetter from './pages/letters/addLetter/CreateLetter';
import SingleConversation from './pages/conversations/singleConversation/SingleConversation';
import { createTheme } from '@mui/material';
import ConversationList from './pages/conversations/conversationsList/ConversationList';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import PageNotFound from './pages/page-404/PageNotFound';
import SingleLetters from './pages/letters/singleLetter/SingleLetters';

const theme = createTheme({});



function App() {
  let location = useLocation();
  const pageNumConvo = localStorage.getItem('pageNumConvo')
  const pageNumLetter = localStorage.getItem('pageNumLetter')
  const sortingFieldConvo = localStorage.getItem('sortingFieldConvo')
  const sortingOrderConvo = localStorage.getItem('sortingOrderConvo')
  const sortingFieldLetters = localStorage.getItem('sortingFieldLetters')
  const sortingOrderLetters = localStorage.getItem('sortingOrderLetters')
  function RequireAuth({ children }) {
    const token = Cookies.get('token')
    if (!token) {
      return <Navigate to="/" />;
    }
    return children;
  }

  useEffect(() => {
    if (pageNumConvo) {
      if (!location.pathname.includes('conversation')) {
        localStorage.removeItem('pageNumConvo')
      }
    }
    if (pageNumLetter) {
      if (!location.pathname.includes('letters')) {
        localStorage.removeItem('pageNumLetter')
      }
    }
    if(sortingFieldConvo){
      if (!location.pathname.includes('conversation')) {
        localStorage.removeItem('sortingFieldConvo')
      }
    }
    if(sortingOrderConvo){
      if (!location.pathname.includes('conversation')) {
        localStorage.removeItem('sortingOrderConvo')
      }
    }
    if(sortingOrderLetters){
      if (!location.pathname.includes('letters')) {
        localStorage.removeItem('sortingOrderLetters')
      }
    }
    if(sortingFieldLetters){
      if (!location.pathname.includes('letters')) {
        localStorage.removeItem('sortingFieldLetters')
      }
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      } />
      <Route path="/conversations" element={
        <RequireAuth>
          <ConversationList />
        </RequireAuth>
      } />
      {/* <Route path="/therapist" element={<Therapist />} /> */}
      {/* <Route path="/letters" element={<LettersList />} /> */}
      {/* <Route path="/therapistProfile" element={<TherapistProfile/>} /> */}
      {/* <Route path="/createTherapist" element={<CreateTherapist/>} /> */}
      {/* <Route path="/createLetter" element={<CreateLetter/>} /> */}
      <Route path="/conversation/:id" element={
        <RequireAuth>
          <SingleConversation />
        </RequireAuth>
      } />
      {/* <Route path="/letters/:id" element={
        <RequireAuth>
          <SingleLetters />
        </RequireAuth>
      } /> */}
        <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
