import React, { useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Select } from '@chakra-ui/react'
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";



const Navbar = (props) => {
    const { sortByMethod } = props
    let location = useLocation();
    const Navigate = useNavigate()
    const [sortByFieldConvo, setSortByFieldConvo] = useState('localDateTime')
    const [sortByFieldLetters, setSortByFieldLetters] = useState('createdOn')
    const [orderByField, setOrderByField] = useState('DESC')
    const [orderByType, setOrderByType] = useState('DESC')
    let locationName = location.pathname.split('/')[1]
    const sort = {
        field: locationName === 'conversations' ? sortByFieldConvo : sortByFieldLetters,
        order: orderByField
    }
    const handleClickASC = () => {
        if (orderByType === 'ASC') {
            setOrderByType('DESC')
        }
        else {
            setOrderByType('ASC')
        }
        sort.order = 'ASC'
        sort.field = locationName === 'conversations' ? sortByFieldConvo : sortByFieldLetters
        sortByMethod(sort)
    }
    const handleClickDESC = () => {
        if (orderByType === 'ASC') {
            setOrderByType('DESC')
        }
        else {
            setOrderByType('ASC')
        }
        sort.order = 'DESC'
        sort.field = locationName === 'conversations' ? sortByFieldConvo : sortByFieldLetters
        sortByMethod(sort)
    }
    const handleChange = (e) => {
        if (locationName === 'conversations') {
            setSortByFieldConvo(e.target.value)
        }
        else {
            setSortByFieldLetters(e.target.value)
        }
        sort.field = e.target.value
        sort.order = orderByType
        sortByMethod(sort)
    }
    return (
        <nav className='flex-no-wrap h-32 relative flex w-full items-center justify-between bg-[#f4f7fe] py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4'>
            <div className='flex justify-between h-20 w-full'>
                <div className='flex items-center p-3'>
                    <p className='text-2xl font-bold'>{locationName.toUpperCase()}</p>
                </div>
                {
                    locationName === 'letters' ?
                        <div className='flex items-center p-3'>
                            <div className='flex items-center p-1'>
                                <button onClick={()=>Navigate('/createLetter')} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                    Create a Letter
                                </button>
                            </div>
                            <div className='flex items-center p-1'>
                                {
                                    orderByType === 'DESC' ? <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                        <FaArrowDown size={20} />
                                    </button>
                                        : <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            <FaArrowDown size={20} />
                                        </button>
                                }
                            </div>
                            <div className='flex items-center p-1'>
                                {
                                    orderByType === 'ASC' ? <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                        <FaArrowUp size={20} />
                                    </button>
                                        : <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            <FaArrowUp size={20} />
                                        </button>
                                }
                            </div>
                            <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
                                <Select placeholder='Sort by' onChange={handleChange} bg='white'>
                                    <option value='type'>Type</option>
                                    {/* <option value='isActive'>Is Active</option>
                                    <option value='expiringOn'>Expiring On</option>
                                    <option value='localDateTime'>Updated Date</option> */}
                                </Select>
                            </div>


                        </div>
                        :
                        locationName === 'conversations' ?
                            <div className='flex'>
                                <div className='flex items-center p-1'>
                                    {
                                        orderByType === 'ASC' ? <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            <FaArrowUp size={20} />
                                        </button>
                                            : <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                <FaArrowUp size={20} />
                                            </button>
                                    }
                                </div>
                                <div className='flex items-center p-1'>
                                    {
                                        orderByType === 'DESC' ? <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            <FaArrowDown size={20} />
                                        </button>
                                            : <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                <FaArrowDown size={20} />
                                            </button>
                                    }
                                </div>
                                <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
                                    <Select placeholder='Sort by' onChange={handleChange} bg='white'>
                                        <option value='type'>Type</option>
                                        <option value='isActive'>Is Active</option>
                                        <option value='expiringOn'>Expiring On</option>
                                        <option value='localDateTime'>Updated Date</option>
                                    </Select>
                                </div>
                            </div>

                            :
                            <div>

                            </div>

                }

            </div>
        </nav>
    )
}

export default Navbar