import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className='flex flex-col gap-5 justify-center items-center h-screen '>
      <div>
        <p className='text-2xl text-slate-500 font-semi-bold'>404 | NOT FOUND</p>
      </div>
      <div>
        <Link to = '/dashboard'>
          <p className='text-2xl text-slate-500 font-semi-bold cursor-pointer'>Go back to home</p>
        </Link>
      </div>
    </div>
  )
}

export default PageNotFound