import { AUTH_ENDPOINT } from "../../config";

export async function login(credentialResponse) {
    try {
        const requestOptions = new Request(AUTH_ENDPOINT + "/.auth/admin/login/google", {
            method: 'POST',
            body: JSON.stringify({ access_token: credentialResponse.clientId, id_token: credentialResponse.credential }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        const response = await fetch(requestOptions)
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function logout(headers) {
    try {
        const requestOptions = new Request(AUTH_ENDPOINT + "/.auth/logout", {
            method: 'POST',
            headers
        });
        const response = await fetch(requestOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        return null;
    }
}